import React from 'react';
import Hour from '../models/Hour';
import * as Icons from 'react-feather';

import commercial from "../images/commercial.mp4";
import commercialPreview from '../images/commercial-preview.jpg';

import '../scss/about.scss';
import '../scss/about-responsive.scss';
import SEO from '../components/seo';

export interface AboutTemplateProps {
    pageContext: {
        about: string,
        hours: Hour[],
        location: string
    }
}

const AboutTemplate: React.FC<AboutTemplateProps> = ({ pageContext }) => {

    const { about, hours, location } = pageContext;

    return (
        <div>
            <SEO title="About Us" description={about} />
            <div className="grey-skewed-wrapper">
                <div style={{
                    top: '5rem',
                    transform: 'rotate(-20deg)',
                    transformOrigin: 'left',
                }} className="grey-skewed"></div>
            </div>
            <div className="about">
                <h1>About Us</h1>
                <hr className="thick-divider" />
                <p className="main-paragraph">
                    {about}
                </p>
                <div className="about-video">
                    <video controls src={commercial} poster={commercialPreview} />
                </div>
                <div className="hours">
                    <div className="section-head">
                        <Icons.Clock />
                        <h2>Hours</h2>
                    </div>
                    {
                        hours.map(hour => (
                            <div key={hour.days} className="hour">
                                <h3 className="days">{hour.days}</h3>
                                <div className="time">{hour.time}</div>
                            </div>
                        ))
                    }
                </div>
                <div className="location">
                    <div className="section-head">
                        <Icons.MapPin />
                        <h2>Location</h2>
                    </div>
                    {location}
                    <div className="map">
                        <iframe title="Location" src="https://maps.google.com/maps?q=Long%20Run%20Mopeds%20%2C219%20Virginia%20Ave%2C%20Vinton&t=&z=17&ie=UTF8&iwloc=&output=embed" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTemplate;